import React, { Component } from 'react';
import FavEmbeds from '../UI/FavEmbeds';
import { connect } from 'react-redux';
import { voteEmbed } from '../actions/embedActions'
import { Helmet } from 'react-helmet';

class Fav extends Component {
    state = {
        embeds: this.props.catalogEmbeds,
        votedEmbeds: localStorage.getItem("votedEmbeds") ? JSON.parse(localStorage.getItem("votedEmbeds")) : [],
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Favorite Releases - Ambient Music</title>
                    <meta name="description" content="Ambient Music curates minimalist, drone, industrial, and dark ambient music on the World Wide Web. Stream ambient music." />
                </Helmet>
                <div>
                    <div><p className="page-title">favorite releases</p></div>
                    <FavEmbeds embeds={this.state.embeds} upvoteEmbed={this.props.voteEmbed} votedEmbeds={this.state.votedEmbeds} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        catalogEmbeds: state.catalogEmbeds
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        voteEmbed: (embed) => dispatch(voteEmbed(embed))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fav);
