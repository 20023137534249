export const voteEmbed = (embed) => {
    return (dispatch) => {
        var votedEmbeds = localStorage.getItem("votedEmbeds") ? JSON.parse(localStorage.getItem("votedEmbeds")) : [];
        if (votedEmbeds.includes(embed)) {
            dispatch({ type: 'ALREADY_VOTED', embed })
            const index = votedEmbeds.indexOf(embed);
            if (index > -1) {
                votedEmbeds.splice(index, 1);
            }
            localStorage.setItem("votedEmbeds", JSON.stringify(votedEmbeds));
        } else {
            dispatch({ type: 'VOTE_EMBED', embed })
            votedEmbeds.push(embed);
            localStorage.setItem("votedEmbeds", JSON.stringify(votedEmbeds))
        }
    }
}