import { connect } from 'react-redux';
import React, { Component } from 'react';
import { voteEmbed } from '../actions/embedActions';
import Embed from '../UI/Embed';
import { Helmet } from 'react-helmet';

class Random extends Component {
  render() {
    var embed = this.props.catalogEmbeds[Math.floor(Math.random() * this.props.catalogEmbeds.length)];
    var votedEmbeds = localStorage.getItem("votedEmbeds") ? JSON.parse(localStorage.getItem("votedEmbeds")) : [];
    var embedButton = votedEmbeds.includes(embed.id) ? "lit" : "unlit";
    return (
      <div>
        <Helmet>
          <title>Random Release - Ambient Music</title>
          <meta name="description" content="Discover minimalist, drone, industrial, and dark ambient music." />
        </Helmet>
        <div className="random-container">
          <div><p className="page-title">random release</p></div>
          <Embed key={embed.id} embed={embed} upvoteEmbed={this.props.voteEmbed} embedButton={embedButton} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    catalogEmbeds: state.catalogEmbeds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    voteEmbed: (embed) => dispatch(voteEmbed(embed))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Random);