import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Embeds from '../UI/Embeds';
import { connect } from 'react-redux';
import { voteEmbed } from '../actions/embedActions'
import { Helmet } from 'react-helmet';

class Home extends Component {
  state = {
    embeds: this.props.catalogEmbeds.slice(0, 2),
    votedEmbeds: localStorage.getItem("votedEmbeds") ? JSON.parse(localStorage.getItem("votedEmbeds")) : [],
    color: 'all',
    hasMore: true
  }
  fetchMoreData = () => {
    const index = this.state.embeds.length;
    if (index >= this.props.catalogEmbeds.length) {
      this.setState({ hasMore: false });
      return
    }
    const arr = this.props.catalogEmbeds.slice(index, index + 2);
    this.setState({
      embeds: this.state.embeds.concat(arr)
    });
  };
  selectColor = (color) => {
    this.setState({
      embeds: this.props.catalogEmbeds
    }, () => {
      this.setState({ color })
    })
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Ambient Music - For work and reflection</title>
          <meta name="description" content="Ambient Music curates minimalist, drone, industrial, and dark ambient music on the World Wide Web. Stream and discover new ambient music." />
        </Helmet>
        <div className="container">
          <div><p className="page-title">ambient music for work and reflection</p></div>
          <div className="color-picker">
            <div className="box black" id="black" onClick={() => { this.selectColor("black") }}></div>
            <div className="box gray" id="gray" onClick={() => { this.selectColor("gray") }}></div>
            <div className="box white" id="white" onClick={() => { this.selectColor("white") }}></div>
            <div className="box pink" id="pink" onClick={() => { this.selectColor("pink") }}></div>
            <div className="box red" id="red" onClick={() => { this.selectColor("red") }}></div>
            <div className="box purple" id="purple" onClick={() => { this.selectColor("purple") }}></div>
            <div className="box blue" id="blue" onClick={() => { this.selectColor("blue") }}></div>
            <div className="box green" id="green" onClick={() => { this.selectColor("green") }}></div>
            <div className="box yellow" id="yellow" onClick={() => { this.selectColor("yellow") }}></div>
          </div>
          <InfiniteScroll
            dataLength={this.state.embeds.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore}
            loader={<p>Loading...</p>}
          >
            <Embeds embeds={this.state.embeds} color={this.state.color} upvoteEmbed={this.props.voteEmbed} votedEmbeds={this.state.votedEmbeds} />
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    catalogEmbeds: state.catalogEmbeds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    voteEmbed: (embed) => dispatch(voteEmbed(embed))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
