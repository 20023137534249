import React from 'react'
import { Helmet } from 'react-helmet';

const About = () => {
    return (
        <div>
            <Helmet>
                <title>About - Ambient Music</title>
                <meta name="description" content="Ambient Music curates minimalist, drone, industrial, and dark ambient music for work and reflection." />
            </Helmet>
            <img alt="ambient music" width="200px" src="./ambient-music.png" />
            <br></br><br></br><br></br><br></br>
            <div className="about-container">
                <p><a href="/">ambientmusic.com</a> curates minimalist, drone, industrial, and dark ambient music for work and reflection.</p>
                <br></br><br></br>
                <p>All releases are from Bandcamp. Please support these wonderful artists!</p>
                <br></br><br></br>
                <p>This site uses one Google Analytics script. Each Bandcamp embed has its own set of Google Analytics scripts.</p>
            </div>
        </div>
    );
}

export default About;
