import React from 'react';
import Embed from '../UI/Embed';

const FavEmbeds = ({ embeds, upvoteEmbed, votedEmbeds }) => {
    if (votedEmbeds === undefined || votedEmbeds.length === 0) {
        return (<div className="about-container">You have not favorited any releases yet!</div>)
    }
    else {
        const embedList = embeds.map(embed => {
            if (votedEmbeds.includes(embed.id)) {
                return (<Embed key={embed.id} embed={embed} upvoteEmbed={upvoteEmbed} embedButton="lit" />)
            }
            else
                return null;
        })
        return (<div className="embed-list">{embedList}</div>)
    }
}

export default FavEmbeds
