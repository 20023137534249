import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Releases from '../UI/Releases';

class Catalog extends Component {
    state = {
        embeds: this.props.catalogEmbeds
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Featured Releases - Ambient Music</title>
                    <meta name="description" content="Featured releases on Ambient Music. Ambient Music curates minimalist, drone, industrial, and dark ambient music for work and reflection." />
                </Helmet>
                <div><p className="page-title">featured releases</p></div>
                <Releases embeds={this.state.embeds} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        catalogEmbeds: state.catalogEmbeds
    }
}

export default connect(mapStateToProps)(Catalog);
