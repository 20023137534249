import React from 'react';
import Embed from '../UI/Embed';

const Embeds = ({ embeds, color, upvoteEmbed, votedEmbeds }) => {
    const embedList = embeds.map(embed => {
        if (color === 'all' || embed.c === color) {
            var embedButton = votedEmbeds.includes(embed.id) ? "lit" : "unlit";
            return (<Embed key={embed.id} embed={embed} upvoteEmbed={upvoteEmbed} embedButton={embedButton} />)
        }
        else
            return null;
    })
    return (<div className="embed-list">{embedList}</div>)
}

export default Embeds
