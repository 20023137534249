import React from 'react';
import Functions from '../Functions';

const Embed = ({ embed, upvoteEmbed, embedButton }) => {
    const variableAttribute = { "src": Functions.getIframeHtml(embed) };
    const variableButtonAttribute = { "id": `button-${embed.id}` };
    const variableImageAttribute = { "id": `img-${embed.id}` };
    const variableIframeAttribute = { "id": `iframe-${embed.id}` };

    var buttonStyle = { "backgroundColor": "#141d26", "border": "none", "outline": "none" }
    var imgStyle = { "width": "30px", "height": "30px" }
    var imgSrc = embedButton === "lit" ? "./lit.png" : "./unlit.png";
    var imgAlt = embedButton === "lit" ? "favorited" : "favorite";

    function removePlaceholder(id) {
        var element = document.getElementById(`iframe-${id}`);
        if (element) {
            element.classList.remove("placeholder");
        }
    };

    return (
        <div key={embed.id} className="embed-container">
            <iframe {...variableIframeAttribute} title={embed.id} className="iframe-embed placeholder" {...variableAttribute} seamless="" onLoad={() => { removePlaceholder(embed.id) }}></iframe>
            <br></br>
            <button {...variableButtonAttribute} style={buttonStyle} title={imgAlt}>
                <img {...variableImageAttribute}
                    alt={imgAlt}
                    src={imgSrc}
                    style={imgStyle}
                    className="hvr-grow"
                    onClick={() => { upvoteEmbed(embed.id) }}
                />
            </button>
        </div>
    )
}

export default Embed
