import React, { Component } from 'react';
import Navigation from './UI/Navigation';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Random from './components/Random'
import Fav from './components/Fav'
import Home from './components/Home'
import Catalog from './components/Catalog'
import About from './UI/About'
import Footer from './UI/Footer'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App container">
          <Navigation />
          <Route path='/favorites' component={Fav} />
          <Route path='/random' component={Random} />
          <Route path='/about' component={About} />
          <Route path='/releases' component={Catalog} />
          <Route exact path='/' component={Home} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
