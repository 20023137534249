module.exports = {
    getIframeHtml: function (embed) {
        var src = '';
        if (embed.hasOwnProperty('t_only')) {
            src = `https://bandcamp.com/EmbeddedPlayer/track=${embed.id}/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/transparent=true/`;
        } else if (embed.hasOwnProperty('t')) {
            src = `https://bandcamp.com/EmbeddedPlayer/album=${embed.id}/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/track=${embed.t}/transparent=true/`;
        } else {
            src = `https://bandcamp.com/EmbeddedPlayer/album=${embed.id}/size=large/bgcol=333333/linkcol=0687f5/tracklist=false/transparent=true/`;
        }
        return src;
    }
}