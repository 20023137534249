import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';

const Navigation = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="black" variant="black" fixed="top" className="sticky-nav">
            <Navbar.Brand href='/'><img
                src="/ambient-music.png"
                width="30"
                className="d-inline-block align-top"
                alt="Ambient Music"
            /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="collapse-menu">
                <Nav className="mr-auto">
                    <Nav.Link href="/random" title="random release">random</Nav.Link>
                    <Nav.Link href="/releases" title="releases">releases</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link href="/about" title="about">about</Nav.Link>
                    <Nav.Link href="/favorites" title="favorite releases"><img
                        src="/lit.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Ambient Music"
                    /></Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigation