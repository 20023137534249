import catalog from '../data/catalog.json';

const initState = {
    catalogEmbeds: catalog.embeds
}

const rootReducer = (state = initState, action) => {
    const imgId = "img-" + action.embed;
    switch (action.type) {
        case 'ALREADY_VOTED':
            document.getElementById(imgId).src = "./unlit.png";
            document.getElementById(imgId).title = "favorite";
            return state
        case 'VOTE_EMBED':
            document.getElementById(imgId).src = "./lit.png";
            document.getElementById(imgId).title = "favorited";
            return state
        default:
            return state
    }
}

export default rootReducer